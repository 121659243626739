<template>
  <div id="occasion-view">
    <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home">
            <router-link :to="path"><h3>
              <i class="fa fa-arrow-left"></i> Back
            </h3>
            <!-- {{page_name}} -->
             </router-link>
          </li>
          <!-- <li class="item blog">
            <strong>Occasion List</strong>
          </li> -->
        </ul>
      </div>
    </div>
    <section class="bg-light">
      <main>
        
      </main>
      <div class="col-md-12">
        <div class="findgft">
                <!-- <router-link to='/friendwishlist_view' class="sm_megamenu_head sm_megamenu_drop "
                            id="sm_megamenu_286" href="">  <h1 class="display-4">Friends & Family Wishlist</h1></router-link>             -->
        </div>

      </div>
      <div class="container-fluid pt-5">
 
    

      </div>
      <div class="container-fluid">
        <div>
          <b-tabs content-class="mt-3">
            <b-tab active>
              <template #title>
                <strong><h2>Occassions</h2></strong>
              </template>
              <div class="row">
                <div class="col-md-4"  style="max-height: 650px; overflow-y: scroll; overflow-x: hidden">
                  <ul>
                    <li v-for="occasion in occasion_list" class="sm_megamenu_title ml-4 my-3" @click="showWishlist(occasion)">
                        <h3 class="my-3">
                          <span class="col-md-3">
                            <img src="@/assets/site/images/calendar.svg" width="30" height="30" class="img-fluid"  alt=""> 
                          </span> 
                          <span class="col-md-9">
                            {{occasion.name}}
                          </span>
                        </h3>
                    </li>
                  </ul>
                </div>
                <div class="col-md-7">
                  <friends-wishlist v-if="show" v-bind:wishlistproducts="wishlistproducts"  v-bind:user_contact_id="user_contact_id" v-bind:page_name="page_name"></friends-wishlist>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="pet_wishlist.length > 0">
              <template #title>
                <strong><h2>Pets Wishlist</h2></strong>
              </template>
              <div class="row">

                <div class="col-md-12">
                  <friends-wishlist  v-bind:wishlistproducts="pet_wishlist"  v-bind:user_contact_id="user_contact_id" v-bind:page_name="page_name"></friends-wishlist>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>


      <div class="container">
        <hr />
      </div>


    </section>

    <!-- End Footer -->

  </div>
</template>
<script>
    import FriendsWishlist from '@/views/site/user/FriendswishlistView'
 

    export default {
        name: "Occasionwishlist",
        data() {
            return {
                loader: false,
                errors: [],
                pages: [],
                wishlistproducts: [],
                occasion_list: [],
                imageshow: false,
                wishlist_status:null,
                show: false,
                occasionshow:true,
                page_name: null,
                path: null,
                user_contact_id: null,
                pet_wishlist: [],
                
            };
        },
        components: { FriendsWishlist },
        created() {
           
            this.getWishlistContacts();
            this.page_name = this.$route.params.page_name;
           // console.log(this.page_name);
            this.path = this.$route.params.path;
            this.user_contact_id = this.$route.params.user_contact_id;

        },
        methods: {
            getWishlistContacts() {
                this.occasion_list = this.$route.params.occasion_list;
                if(this.$route.params.pet_wishlist){
                  this.pet_wishlist = this.$route.params.pet_wishlist;
                }

            },  
            showWishlist(occasion){
            this.show = true;
            this.wishlistproducts = occasion.products;
            this.page_name = this.$route.params.page_name;
            window.scrollTo(0, 0);
          } ,          
        }
    }
</script>